import { getToken } from "@/libs/auth";
import store from "@/store";

function circulationTree (origin, vm) {
    origin.map((item) => {
        vm.$set(item, 'menuSel', false);
        vm.$set(item, 'open', false);
        vm.$set(item, 'menuChildSel', false);
        if (item.children) {
            circulationTree(item.children, vm)
        }
    })

    return origin;
}

function selTreeRoute (origin, target, vm) {
    if (!target) { return  false}
    if (Array.isArray(origin)) {
        origin.forEach((item) => {
            if (item.name == target.name || target.meta?.selMenuId === item.menuId) {
                vm.$set(item, 'menuSel', true)
            } else {
                vm.$set(item, 'menuSel', false)
            }

            if (item.children && item.children.length > 0) {
                selTreeRoute (item.children, target, vm)
            }
        })
    }
    return origin
}

function treeFindPath(tree, func, field = "", path = []) {
    if (!tree) return []
    for (const data of tree) {
        field === "" ? path.push(data) : path.push(data[field]);
        if (func(data)) return path
        if (data.children) {
        const findChildren = treeFindPath(data.children, func, field, path)
        if (findChildren.length) return findChildren
        }
        path.pop()
    }
    return []
}

function selTreeParentRoute (tree, target, vm) {
    tree.forEach((item) => {
        if (target.indexOf(item.menuId) >= 0) {
            vm.$set(item, 'menuChildSel', true)
        } else {
            vm.$set(item, 'menuChildSel', false)
        }

        if (item.children && item.children.length > 0) {
            selTreeParentRoute (item.children, target, vm)
        }

    })

    return tree
}

function treeRouteOpen (origin, tar, vm, staticRouteMenu) {
    var target = tar.name;
    const staticRouter = staticRouteMenu.find(item => {
        return item.menuName === target;
    })
    if (staticRouter && Object.keys(staticRouter).length > 0) {
        target = staticRouter.target;
    };


    let selTreeArr = getTreeParent (origin, target);
    setTreeOpen(selTreeArr, origin);
}

function getTreeParent (origin, tar) {
    for (let i in origin) {
        if(origin[i].name == tar){
            return [origin[i]]
        }
        if(origin[i].children){
            let node = getTreeParent(origin[i].children,tar);
            if(node!==undefined){
                return node.concat(origin[i]);
            }
        }
    }
}

function setTreeOpen (selTreeArr, origin) {
    if (selTreeArr && origin) {
        selTreeArr.forEach((item) => {
            origin.forEach((subitem) => {
                if (subitem.name == item.name && subitem.hasOwnProperty('open') && subitem.children) {
                    subitem.open = true;
                    if (subitem.children) {
                        setTreeOpen(selTreeArr, subitem.children)
                    }
                };
            })
        })
    }
}

export function getFrameUrl(tar) {
    /**
     * frameUrl  外链地址
     * isNeedSso 是否需要单点登录   1需要  2不需要
     */
    let { frameUrl } = tar;

    if (tar.isNeedSso === '1') {
        const index = frameUrl.indexOf('?')
        const str = index === -1 ? '?' : '&'

        frameUrl += str +'token=' + getToken() + '&schoolId=' + store.state.schoolId;
        if (tar.menuId === '02040101') {
            frameUrl += '&mobile=' + store.state.loginName;
        }
        console.log(store.state,'用户信息')
        if (tar.menuId === '020207') {
            frameUrl += '&ori=wjwp';
        }
        if(store.state.addresUKey){
            frameUrl += '&addresUKey=' + store.state.addresUKey;
        }
        if (!frameUrl.includes('projectMark')) {
            frameUrl += '&projectMark=' + store.state.projectMark || JSON.parse(localStorage.projectMark);
        }
    }
    return frameUrl
}

export {
    circulationTree,
    selTreeRoute,
    treeFindPath,
    selTreeParentRoute,
    treeRouteOpen
}
