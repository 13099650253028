var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{class:[
        _vm.text ? 'button-text' : 'button',
        _vm.buttonSize ? 'button-' + _vm.buttonSize : '',
        _vm.type ? 'button-' + _vm.type : '',
        {
            'is-disabled': _vm.buttonDisabled, //
            'is-loading': _vm.loading,
        }
    ],style:({
        'border-color': '',
        'background-color': _vm.backgroundColor,
        'color': _vm.color,
        'font-size': _vm.fontSize,
        'margin-left': _vm.marginLeft
    }),attrs:{"disabled":_vm.disabled || _vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.handleClick.apply(null, arguments)}}},[(_vm.loading)?_c('i',{staticClass:"el-icon-loading"}):_vm._e(),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }