<template>
    <button
        @click.prevent="handleClick"
        :disabled="disabled || loading"
        :style="{
            'border-color': '',
            'background-color': backgroundColor,
            'color': color,
            'font-size': fontSize,
            'margin-left': marginLeft
        }"
        :class="[
            text ? 'button-text' : 'button',
            buttonSize ? 'button-' + buttonSize : '',
            type ? 'button-' + type : '',
            {
                'is-disabled': buttonDisabled, //
                'is-loading': loading,
            }
        ]"
    >
        <i class="el-icon-loading" v-if="loading"></i>
        <slot></slot>
    </button>
</template>
<script>
    export default {
        name: "XkButton",
        props:{
            type: {
                type: String
            },
            text: {
                type: Boolean
            },
            size: {
                type: String
            },
            loading: {
                type: Boolean
            },
            disabled: {
                type: Boolean
            },
            backgroundColor: {
                type: String
            },
            color: {
                type: String
            },
            fontSize: {
                type: String
            },
            marginLeft: {
                type: String
            }
        },
        computed: {
            buttonDisabled () {
                return this.disabled
            },
            buttonSize () {
                return this.size
            }
        },
        methods: {
            handleClick (ev) {
                this.$emit('click', ev)
            }
        }
    }
</script>
<style scoped>
    @import url('../../../css/button.css');
</style>
